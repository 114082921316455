var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-item"},[_c('page-title',{scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")])]},proxy:true}])}),(_vm.formModel !== null)?_c('form-preview',{attrs:{"form":_vm.formModel},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}}):_vm._e(),(_vm.formModel !== null)?_c('div',{staticClass:"flex flex-col xl:flex-row gap-5"},[_c('div',{staticClass:"order-2 xl:order-1 w-full xl:w-1/2"},[(_vm.formModel.config.fields !== null)?_c('fields-list',{attrs:{"fields":_vm.formModel.config.fields,"disabled":_vm.disabledFields},on:{"updateFields":function($event){_vm.formModel.config.fields = $event}}}):_vm._e()],1),_c('a-card',{staticClass:"order-1 xl:order-2 w-full xl:w-1/2 self-start",attrs:{"body-style":_vm.cardStyles},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!_vm.disabledFields)?_c('div',{staticClass:"select-none",on:{"click":_vm.handleSaveClick}},[_c('a-icon',{attrs:{"type":"save"}}),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1):_vm._e(),_c('div',{staticClass:"select-none",on:{"click":function($event){_vm.isActive = true}}},[_c('a-icon',{attrs:{"type":"eye"}}),_vm._v(" "+_vm._s(_vm.$t('form_preview'))+" ")],1)]},proxy:true}],null,false,1376641828)},[_c('text-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.formModel,
            'key': 'name',
            'validation': 'required',
            'disabled': _vm.disabledFields
          }
        }}}),_c('editor-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.formModel,
            'key': 'description',
            'disabled': _vm.disabledFields,
            'disabledPanelElements': ['tgspoiler', 'strike'],
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.formModel,
            'key': 'disposable',
            'disabled': _vm.disabledFields
          }
        }}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }