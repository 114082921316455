var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{staticClass:"form-preview",attrs:{"placement":"right","width":_vm.drawerWidth,"visible":_vm.previewOpen,"title":_vm.$t('form_preview')},on:{"close":function($event){_vm.previewOpen = false}}},[_c('full-form',{attrs:{"name":_vm.form.name,"description":_vm.form.description},model:{value:(_vm.frontendFields),callback:function ($$v) {_vm.frontendFields=$$v},expression:"frontendFields"}}),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    })},[_c('a-button',{attrs:{"type":"primary","icon":"eye"},on:{"click":_vm.testForm}},[_vm._v(" "+_vm._s(_vm.$t('test_form_button_title'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }