var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-field"},[_c('select-input',{staticClass:"form-field-select",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.field,
        'key': 'type',
        'prefix': 'form_field_',
        'options': _vm.fieldTypeOptions,
        'clearable': false,
        'disabled': _vm.disabled
      }
    }}}),_c('text-input',{staticClass:"base-form-fields__name",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.field,
        'key': 'name',
        'prefix': 'form_field_',
        'validation': 'required',
        'disabled': _vm.disabled
      }
    }}}),_c('text-input',{staticClass:"base-form-fields__description",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.field,
        'key': 'description',
        'prefix': 'form_field_',
        'disabled': _vm.disabled,
      }
    }}}),(_vm.field.type !== _vm.FormFieldType.Number && _vm.field.type !== _vm.FormFieldType.Double && _vm.field.type !== _vm.FormFieldType.Files)?_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.field,
        'key': 'placeholder',
        'prefix': 'form_field_',
        'options': _vm.fieldTypeOptions,
        'clearable': false,
        'disabled': _vm.disabled,
      }
    }}}):_vm._e(),_c('switch-input',{staticClass:"base-form-fields__required",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.field,
        'key': 'required',
        'prefix': 'form_field_',
        'disabled': _vm.disabled
      }
    }}}),_c('config-field',{staticClass:"select-wrapper mx-2",attrs:{"title":_vm.$t('form_field_property_select'),"disabled":_vm.disabled}},[_c('a-select',{staticClass:"w-full mt-2",attrs:{"labelInValue":"","disabled":_vm.disabled},on:{"change":_vm.handleChange}},[_c('a-select-opt-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('set_user_property_system_properties_options'))+" ")]},proxy:true}])},_vm._l((_vm.freeSystemProperties),function(property,index){return _c('a-select-option',{key:("system-" + index),attrs:{"value":property.value}},[_vm._v(" "+_vm._s(property.label)+" ")])}),1),_c('a-select-opt-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('set_user_property_custom_properties_options'))+" ")]},proxy:true}])},_vm._l((_vm.freeCustomProperties),function(property,index){return _c('a-select-option',{key:("custom-" + index),attrs:{"value":property.value}},[_vm._v(" "+_vm._s(property.label)+" ")])}),1)],1)],1),(_vm.field.type === _vm.FormFieldType.String)?_c('switch-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'validatorExists',
        'prefix': 'form_field_',
        'disabled': _vm.disabled
      }
    }},on:{"change":_vm.onValidatorExistsChange}}):_vm._e(),(_vm.field.type === _vm.FormFieldType.String && _vm.validatorExists)?_c('nested-content',[_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.field.validator,
          'key': 'pattern',
          'prefix': 'form_string_field_',
          'disabled': _vm.disabled
        }
      }},on:{"change":_vm.onPatternChange}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.field.validator,
          'key': 'negate',
          'prefix': 'form_string_field_',
          'disabled': _vm.disabled
        }
      }}}),_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.field.validator,
          'key': 'message',
          'prefix': 'form_string_field_',
          'disabled': _vm.disabled
        }
      }}})],1):_vm._e(),(_vm.field.type === _vm.FormFieldType.Files)?[_c('select-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.field,
          'key': 'file_type',
          'options': _vm.formFileTypeOptions,
          'disabled': _vm.disabled,
          'clearable': false
        },
      }}})]:_vm._e(),((_vm.field.type === _vm.FormFieldType.Multiselect || _vm.field.type === _vm.FormFieldType.Select) && _vm.field.options)?[_c('chips-input',{staticClass:"base-form-fields__select-options",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.field,
          'key': 'options',
          'prefix': 'form_field_',
          'validation': 'required',
          'disabled': _vm.disabled
        }
      }}})]:_vm._e(),(_vm.field.type === _vm.FormFieldType.Files || (_vm.field.type === _vm.FormFieldType.Multiselect && _vm.field.options))?[_c('number-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.field,
          'key': 'limit',
          'min': 1,
          'disabled': _vm.disabled
        }
      }}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }