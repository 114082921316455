






















































































































































































































import { FormFields, FormFieldType, FormFileType, StringValidator } from '@/includes/types/Form.types'
import { InputSetups } from '@/mixins/input-setups'

import {
  Property,
  PropertyFieldType
} from 'piramis-base-components/src/components/BotProperties/components/Properties/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Component, Emit, Mixins, Prop, VModel, Watch } from 'vue-property-decorator'
import { snakeCase } from 'lodash'

@Component({
  components: {
    Accordion,
    NestedContent,
    ConfigField
  },
  data() {
    return {
      FormFieldType,
    }
  }
})
export default class FormFieldConstructor extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel({ type: Object }) field!: FormFields

  @Prop({ type: Boolean }) disabled!: boolean

  @Prop({ type: Array }) selectedProperties!:Array<number>

  @Emit()
  updateFieldType(type: FormFieldType): FormFieldType {
    return type
  }

  @Watch('field.type')
  onFieldChange(newModel:FormFieldType):void {
    this.updateFieldType(newModel)
  }

  validatorExists = false

  get fieldTypeOptions():Array<SelectOption> {
    return [
      { label: this.$t(`form_field_type_${ FormFieldType.String.toLowerCase() }`), value: FormFieldType.String },
      { label: this.$t(`form_field_type_${ FormFieldType.Number.toLowerCase() }`), value: FormFieldType.Number },
      { label: this.$t(`form_field_type_${ FormFieldType.Select.toLowerCase() }`), value: FormFieldType.Select },
      { label: this.$t(`form_field_type_${ FormFieldType.Multiselect.toLowerCase() }`), value: FormFieldType.Multiselect },
      { label: this.$t(`form_field_type_${ FormFieldType.Date.toLowerCase() }`), value: FormFieldType.Date },
      { label: this.$t(`form_field_type_${ FormFieldType.DateTime.toLowerCase() }`), value: FormFieldType.DateTime },
      { label: this.$t(`form_field_type_${ FormFieldType.Double.toLowerCase() }`), value: FormFieldType.Double },
      { label: this.$t(`form_field_type_${ FormFieldType.Files.toLowerCase() }`), value: FormFieldType.Files },
    ]
  }

  get freeSystemProperties(): Array<{ label: string, value: number }> {
    return (this.$store.state.AdminTemplate.runtime_config.system_properties as Array<Property>)
      .filter(this.systemPropertiesFilter)
      .map((prop) => {
        return {
          label: this.$t(`${ snakeCase(prop.name) }_option`).toString(),
          value: prop.id
        }
      })
  }

  get freeCustomProperties(): Array<{ label: string, value: number }> {
    return this.$store.state.boardsState.activeBoard!.config.properties
      .filter(this.systemPropertiesFilter)
      .map(({ name, id }) => {
        return {
          label: name,
          value: id,
        }
      })
  }

  get formFileTypeOptions():Array<SelectOption> {
    return [
      { label: this.$t(`form_file_type_${ FormFileType.Media.toLowerCase() }`), value: FormFileType.Media },
      { label: this.$t(`form_file_type_${ FormFileType.Document.toLowerCase() }`), value: FormFileType.Document },
    ]
  }

  systemPropertiesFilter(prop: Property):boolean {
    if (!this.selectedProperties.includes(prop.id)) {
      if (
        this.field.type === FormFieldType.String
        && (prop.type === PropertyFieldType.Email
          || prop.type === PropertyFieldType.String
          || prop.type === PropertyFieldType.Phone)
      ) {
        return true
      } else if ([ FormFieldType.Multiselect, FormFieldType.Files, FormFieldType.Select ].includes(this.field.type) && prop.type === PropertyFieldType.String) {
        return true
      } else {
        return this.field.type.toString() === prop.type.toString()
      }
    }

    return false
  }

  handleChange({ key }:any):void {
    this.field.property_id = key
  }

  onValidatorExistsChange({ checked }:{ checked: boolean, event: Event }):void {
    if (this.field.type === FormFieldType.String) {
      if (!this.field?.validator) {
        this.$set(this.field, 'validator', {})
      }

      if (!checked) {
        this.field.validator = null
      } else {
        this.field.validator = {} as StringValidator
        this.$set(this.field.validator, 'pattern', '')
        this.$set(this.field.validator, 'negate', false)
        this.$set(this.field.validator, 'message', '')
      }
    }
  }

  onPatternChange(value:Event):void {
    if (this.field.type === FormFieldType.String && this.field.validator) {
      this.field.validator.pattern = (value.target as HTMLInputElement).value.trim()
    }
  }

  mounted():void {
    this.validatorExists = this.field.type === FormFieldType.String && !!this.field?.validator
  }
}
