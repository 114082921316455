




































































































import PageTitle from '@/components/PageTitle.vue'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import { InputSetups } from '@/mixins/input-setups'
import FieldsList from '@/components/Forms/FieldsList.vue'
import { Form, FormFieldType } from '@/includes/types/Form.types'
import { FormService } from '@/includes/services/FormService'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import FormPreview from '@/components/Forms/components/FormPreview.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import WindowResizeMixin from 'piramis-base-components/src/logic/helpers/WindowResizeMixin'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    FormPreview,
    PageTitle,
    FieldsList
  }
})
export default class FormItem extends Mixins<UseFields, InputSetups, WindowResizeMixin>(UseFields, InputSetups, WindowResizeMixin) {
  formModel: Form | null = null

  isActive = false

  disabledFields = false

  get action(): BaseItemViewAction {
    return this.$route.params.actionType.toString() as BaseItemViewAction
  }

  get formKey(): string {
    return this.$route.params.formKey.toString()
  }

  get cardStyles():Partial<CSSStyleDeclaration> {
    if (this.windowWidth < 1280) {
      return {
        padding: '5px'
      }
    }

    return {}
  }

  validateFormFields(formModel:Form): Promise<Form> {
    return new Promise((resolve, reject) => {
      if (formModel.name === '') {
        reject(this.$t('create_form_form_title_empty_error'))
      }

      if (!formModel.config.fields.length) {
        reject(this.$t('create_form_fields_empty_error'))
      }

      formModel.config.fields.forEach(f => {
        if (f.name === '') {
          reject(this.$t('create_form_field_name_empty_error', [ f.name ]))
        } else if (f.type === FormFieldType.Multiselect && f.options.length < 1) {
          reject(this.$t('create_form_field_option_empty_error', [ f.name ]))
        }
      })

      resolve(formModel)
    })
  }

  handleSaveClick():void {
    if (this.formModel !== null) {
      if (this.action === BaseItemViewAction.New) {
        this.createForm(this.formModel)
      }

      if (this.action === BaseItemViewAction.Edit) {
        this.saveForm(this.formModel)
      }
    }
  }

  saveForm(formModel: Form):void {
    this.validateFormFields(formModel)
      .then(form => {
        this.$store.dispatch('updateForm', form)
          .then(() => this.$router.push({ name: "Forms" }))
      })
      .catch(errorNotification)
  }

  createForm(formModel: Form):void {
    this.validateFormFields(formModel)
      .then(form => {
        this.$store.dispatch('createForm', form)
          .then(() => this.$router.push({ name: "Forms" }))
      })
      .catch(errorNotification)
  }

  getForm():void {
    this.$baseTemplate.loader.open()

    FormService.getForm('tg', {
      board_key: this.$store.state.boardsState.activeBoard!.board,
      key: this.formKey
    })
      .then(({ form }) => {
        const { answers, ...formModel } = form
        this.formModel = formModel
      })
      .catch(errorNotification)
      .finally(() => {
        this.$baseTemplate.loader.close()
      })
  }

  mounted(): void {
    if (this.action === BaseItemViewAction.Edit) {
      this.getForm()
    } else if (this.action === BaseItemViewAction.Watch) {
      this.getForm()
      this.disabledFields = true
    } else {
      this.formModel = {
        name: '',
        description: '',
        disposable: false,
        config: {
          fields: []
        }
      }
    }
  }
}
